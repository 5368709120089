@import 'variables';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: $dark-gray-color;
  font-family: $primary-font-stack;
  font-weight: 400;
  font-size: 18px;
  color: $white-color;
}

h1 {
  font-family: $secondary-font-stack;
  font-weight: 600;
  font-size: 64px;
  color: $white-color;
}

h2 {
  font-family: $secondary-font-stack;
  font-weight: 600;
  font-size: 42px;
  color: $white-color;
}

/* CustomScrollbar.scss */

::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  height: 12px; /* Height of the scrollbar for horizontal scroll */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  border-radius: 10px; /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  
  &:hover {
    background: #555; /* Color of the thumb on hover */
  }
}

::-webkit-scrollbar-thumb:active {
  background: #333; /* Color of the thumb when active */
}
